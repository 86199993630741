import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    Routes,
    Route,
    useLocation,
    Navigate,
    useNavigate,
} from "react-router-dom";
import { Footer } from "../components/Footer/Footer";
import TNavBar from "../components/NavBar/NavBar/NavBar";
import { HomePage } from "../pages/Home";
import { LanguagePage } from "../pages/LanguagePage";
import { MediaGallery } from "../pages/MediaGallery";
import { NewslistPage } from "../pages/NewslistPage";

import { NewsPage } from "../pages/NewsPage";
import { RegisterOIDC } from "../pages/RegisterOIDC";
import { useEffect } from "react";
import { LoginPage } from "../pages/Verifylogin";


export const AppRouter = () => {
    const location = useLocation()
    const navegate = useNavigate()

    const { i18n, t } = useTranslation()
    const language = location.pathname.split('/')[1]

    if (language !== i18n.language) {
        i18n.changeLanguage(language)
    }
    if (location.pathname === "/") {
        const languaje = i18n.language.toLowerCase()
        navegate(`/${languaje}`)
    }


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname])
    return (
        <div>
            <title>{t("app.tilte")}</title>
            <TNavBar>
                <Routes>
                    <Route path={`/${i18n.language}`} element={<HomePage />} />

                    <Route path={`/signin-oidc`} element={<RegisterOIDC />} />

                    <Route path={`/${i18n.language}/gallery`} element={<MediaGallery />} />
                    {/* <Route path={`/${i18n.language}/news`} element={<NewslistPage />} /> */}
                    <Route path={`/${i18n.language}/language`} element={<LanguagePage />} />
                    {/* <Route path={`/${i18n.language}/news/:day/:mm/:yy/:name`} element={<NewsPage />} /> */}
                    <Route path={`/redirect-login`} element={<LoginPage />} />
                    <Route path={`/${i18n.language}/redirect-login`} element={<LoginPage />} />

                    <Route path={`/*`} element={<Navigate to={"/"} />} />

                </Routes>
            </TNavBar>
            <Footer />
        </div>
    )


}
