import { Box, Button, Divider, Grid, ThemeProvider, Typography } from "@mui/material"
import "../../styles/CSS/Home.css"
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { TButton } from "../CustomComponents/TesseractButton";
import { HeroItems } from "../../objects/HomeObj";
import { useTranslation } from "react-i18next";
import Mayor1 from "../img/gg-Hero.png"
import Chile from "../img/Logo_MarcaChile_Caja Roja.png"

import mapFromSky from "../img/GG_mapa_2.png"

import ggName from "../img/gg-logoName2.png"
import { useAuth } from "react-oidc-context";

export const Hero = () => {
    const { t } = useTranslation()
    const auth = useAuth();


    return (

        <Box sx={{ height: `auto` }} className="SectionsWithDividerzOne">
            <Box sx={{ display: { xs: "none", md: "flex", height: "auto", alignItems: "center", justifyContent: "center" } }}>
                <img style={{ width: "100%", }} src={Mayor1} alt="hero image" />
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none", height: "auto", alignItems: "center", justifyContent: "center" } }}>
                <img style={{ height: "500px", }} src={mapFromSky} alt="hero image" />
            </Box>
            <Box sx={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.4 )", width: "100%", height: "1000px", top: "0%" }}></Box>

            <Box sx={{ position: "absolute", display: "flex", alignItems: "center", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
                <Grid container>
                    <Grid item sx={{ minWidth: "200px", display: "flex", flexDirection: "column", justifyContent: "center" , alignItems:'center'}}>
                        <Box sx={{ width: { xs: "300px", md: "500px", lg: "800px" } }}>
                            <img src={ggName} width="100%" />
                        </Box>
                        <Box sx={{ height: { xs: "100px" } }}>
                            <img src={Chile} height="100%" />
                        </Box>

                        {/* <a href={HeroItems.buttonLink} style={{ margin: "0 auto", textDecoration:"none"}}>
                            <TButton text={t("home.someText")} />
                        </a> */}
                        {/* <TButton text={t("home.playNow")} /> */}

                        {/* <Button onClick={() => void auth.signinRedirect()} color="secondary" variant="contained" sx={{ margin: "0 auto", fontSize: "1.2rem", borderRadius: "20px" }}>
                            <ArrowCircleRightIcon sx={{ marginRight: "0.3rem" }} />
                            {t("home.playNow")}
                        </Button> */}
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}
