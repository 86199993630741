import { createSlice } from '@reduxjs/toolkit'

export interface userStore {
  status: string,
  token: string | null,
  email: string,
  name: string,
  lastname: string
  // photoURL: string,
  // errorMessage: string | null,
  birthdate: string,
  roles: string,
  profileImage: string
}

const initialState: userStore = {
  status: "notLoggedIn",
  token: null,
  email: "",
  name: "",
  lastname: "",
  birthdate: "",
  roles: "",
  profileImage: ""
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.status = "loggedIn";
      state.token = payload.token;
      state.email = payload.email;
      state.name = payload.sub;
      state.lastname = payload.family_name;
      state.roles = payload.roles;
      state.birthdate = payload.birthdate;
      state.profileImage = payload.profileImage;
    },
    logout: (state) => {
      state.status = "notLoggedIn";
      state.token = "";
      state.email = "";
      state.name = "";
      state.lastname = "";
      state.roles = "";
      state.birthdate = "";
      window.localStorage.removeItem("jwt")
    },
    checkingCredentials: (state) => {
      state.status = "checking";
    },

  }
})

export const { login, logout, checkingCredentials } = authSlice.actions