import { Box, Grid, List, ListItem, ListItemIcon, ThemeProvider } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { appTheme } from "../styles/MUI/Theme"
export const LanguagePage = () => {
    const { t, i18n } = useTranslation()
    return (
        <ThemeProvider theme={appTheme}>
            <Box mt={"70px"} height={"100vh"} sx={{backgroundColor:"#f2f2f2"}}>
                <Grid container direction={"row"} px={2} pt={3} alignItems={"center"}>
                    <List>
                        <ListItem>
                            <ListItemIcon><CircleIcon /></ListItemIcon>
                            <Link to={`/en-us`} style={{ textDecoration: "none", color: "#231f20" }}>{t("language.english")}</Link>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><CircleIcon /></ListItemIcon>
                            <Link to={`/es-es`} style={{ textDecoration: "none", color: "#231f20" }}>{t("language.spanish")}</Link>
                        </ListItem>
                    </List>
                </Grid>
            </Box>
        </ThemeProvider>
    )
}