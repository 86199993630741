import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { appTheme } from '../../../styles/MUI/Theme';
import { Avatar, Button, CircularProgress, Divider, Drawer, IconButton, List, Menu, MenuItem, ThemeProvider, Tooltip } from '@mui/material';
import "../../../styles/CSS/Nav.css"
import { Link, useLocation } from 'react-router-dom';
import { NavRoutes } from './NavItems';
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TDrawerItemsList from './TDrawerItemsList';
import { DropDownItem } from './DropdownItem';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import logo from "../../img/gg-logoName2.png"
import image from "../../img/TesseractFullLogo.png"
import { loginAuth, startLogout, verifyToken } from '../../../store/auth/authThunk';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { LoadingCube } from '../../CustomComponents/loader/LoadingCube';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}
const drawerWidth = 350;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

function ElevationScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export default function TNavBar(props: Props) {

    const { t, i18n } = useTranslation()
    const { status, name, lastname, profileImage } = useAppSelector(state => state.auth)
    const location = useLocation()
    const dispatch = useAppDispatch()
    const theme = useTheme();
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);


    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        window.localStorage.removeItem("jwt")
        dispatch(startLogout());
    }

    React.useEffect(() => {
        const token = window.localStorage.getItem("jwt");

        if (status != "logged" && location.pathname != "/redirect-login" && token) {
            console.log(window.location.pathname);
            dispatch(verifyToken())
        }

    }, [])

    return (
        <div>
            <ThemeProvider theme={appTheme}>
                <React.Fragment >
                    <CssBaseline />
                    <ElevationScroll {...props}>
                        <div>
                            <AppBar color='primary' className='customNav'>
                                <Toolbar sx={{ justifyContent: "space-between" }}>
                                    <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} >
                                        <a href='https://tesseractsoftwares.com/' className='logoNav' >
                                            <img src={image} alt='Tesseract Logo' style={{ width: "140px" }} />
                                            {/* <Typography variant="h6" component="div" sx={{ fontFamily: "Roboto !important", fontWeight: "700", paddingX: "10px", color: "#fff" }}>
                                                TESSERACT
                                            </Typography> */}
                                            <div className='navSeccion' />
                                        </a>
                                        <Link to={`/${i18n.language}`} className='logoNav' >
                                            <img src={logo} width="100px" />
                                        </Link>
                                    </Box>
                                    <IconButton
                                        aria-label="open drawer"
                                        edge="end"
                                        onClick={handleDrawerOpen}
                                        // sx={{ ...(open && { display: 'none' }) }}
                                        sx={{ display: { xs: "flex", md: "none" }, color: "#fff" }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                        <NavRoutes />
                                        <DropDownItem />
                                    </Box>
                                    {
                                        status === "loggedIn" &&
                                        <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
                                            <Typography variant='h6' sx={{ mr: "1rem" }}>Hello, {name}</Typography>

                                            <Tooltip title="Open settings">
                                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                    <Avatar alt={`${name} ${lastname}`} src={`data:image/png;base64, ${profileImage}`} />

                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                sx={{ mt: "45px" }}
                                                id="menu-appbar"
                                                anchorEl={anchorElUser}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right"
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right"
                                                }}
                                                open={Boolean(anchorElUser)}
                                                onClose={handleCloseUserMenu}
                                            >
                                                {/* {settings.map((setting) => (
                                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                                        <Typography textAlign="center">{setting}</Typography>
                                                    </MenuItem>
                                                ))} */}
                                                <MenuItem onClick={() => window.location.href = process.env.REACT_APP_ACCOUNT_URL as string}>
                                                    <Typography textAlign="center">{t("Navbar.profile")}</Typography>
                                                </MenuItem>
                                                <MenuItem onClick={handleLogout}>
                                                    <Typography textAlign="center">{t("Navbar.logout")}</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    }
                                    {
                                        status === "notLoggedIn" &&
                                        <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
                                            {/* <Link to={`/${i18n.language}/auth/login`}> */}
                                            <Button variant="contained" color="secondary"
                                                // onClick={() => { window.location.href = `${process.env.REACT_APP_TAUTHWEB}/?url=${window.location.origin + window.location.pathname}` }}
                                                onClick={() => {
                                                    dispatch(loginAuth())
                                                }}
                                            >{t("Navbar.signin")}</Button>
                                            {/* </Link> */}
                                        </Box>

                                    }
                                    {
                                        status === "checking" &&
                                        <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
                                           <CircularProgress color='info' />
                                        </Box>

                                    }

                                </Toolbar>

                            </AppBar>
                            {/* <Main open={open}>

                                <DrawerHeader />
                            </Main> */}
                            <Drawer
                                PaperProps={{
                                    sx: {
                                        backgroundColor: "#231f20",
                                        color: "#fff",
                                        maxWidth: "300px",
                                        display: { xs: "block", md: "none" }
                                    }
                                }}

                                sx={{
                                    width: drawerWidth,
                                    flexShrink: 0,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                    },
                                }}
                                variant="persistent"
                                anchor="left"
                                open={open}
                                color="primary"
                            >
                                <DrawerHeader sx={{ justifyContent: "space-between" }}>
                                    <Box sx={{ display: { xs: "flex", md: "none" }, m: 1 }} >
                                        <a href='https://tesseractsoftwares.com/' className='logoNav' >
                                            <img src={image} alt='Tesseract Logo' style={{ width: "140px" }} />
                                            {/* <Typography variant="h6" component="div" sx={{ fontFamily: "Roboto !important", fontWeight: "700", paddingX: "10px", color: "#fff" }}>
                                                TESSERACT
                                            </Typography> */}
                                            <div className='navSeccion' />
                                        </a>
                                        <a href={`/${i18n.language}`} className='logoNav' >
                                            <img src={logo} width="100px" />
                                        </a>
                                    </Box>
                                    <IconButton onClick={handleDrawerClose} >
                                        {theme.direction === 'rtl' ? <ChevronLeftIcon style={{ color: "#fff" }} /> : <ChevronRightIcon style={{ color: "#fff" }} />}
                                    </IconButton>

                                </DrawerHeader>
                                <Divider />
                                <List component="nav">
                                    <TDrawerItemsList />
                                </List>
                            </Drawer>
                        </div>
                    </ElevationScroll>
                    {/* <Toolbar /> */}
                    <Box sx={{ margin: "0 auto", padding: "0 !important" }}>
                        {props.children}
                    </Box>
                </React.Fragment>
            </ThemeProvider>
        </div>
    );
}