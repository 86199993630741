import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Virtual } from 'swiper';
import 'swiper/css'; import 'swiper/css/virtual'; import "swiper/css/navigation";
import { Box, Grid, Typography } from '@mui/material';
import { TButton } from '../../CustomComponents/TesseractButton';
import { MechanicsItem, MinigamesItem } from '../../../models/Home';
import { MechanicsDescription, MechanicsItems } from '../../../objects/HomeObj';
import { useTranslation } from 'react-i18next';
import ggMap from "../../img/GG_mapa_1.png"
export const Mechanics = () => {

    const { t } = useTranslation()

    return (
        <Box sx={{ height: { xs: "800px", lg: "auto" } }} className="Lastsection">
            <Box sx={{ display: { xs: "flex", md: "flex", height: { xs: "800px", md: '900px', lg: "auto" }, alignItems: "center", justifyContent: "center" } }}>
                <Box
                    component="img"
                    sx={{
                        width: { xs: 'auto', lg: "100%" }, height: { xs: '800px', md: '900px', lg: 'auto' }
                    }}
                    alt="Map image"
                    src={ggMap}
                />
            </Box>
            {/* <Box sx={{ display: { xs: "flex", md: "none", height: "auto", alignItems: "center", justifyContent: "center" } }}>
                <img style={{ height: "500px", }} src={mapFromSky} alt="hero image" />
            </Box> */}
            <Box sx={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.4 )", width: "100%", height: { xs: "800px", md: '900px', lg: "100%" }, top: "0%" }}></Box>

            <Typography variant="h2" sx={{ top: 0, position: "absolute", width: "100%", WebkitTextStroke: "1px black", paddingTop: "4rem", textAlign: "center", color: "#00C04A", fontFamily: "'Modak'", fontSize: { md: "5rem", xs: "3rem" }, textShadow: "0.1em 0.1em 0.1em #333" }}>
                HyperCasual <span style={{ color: "#ffa400" }}>MMO</span>
            </Typography>

            <Box sx={{ height: "100%", alignItems: { xs: 'center', lg: "end" }, paddingBottom: 11, paddingX: { xs: 3, md: 0 }, position: "absolute", top: { xs: '25%', sm: "20%", md: "15%", lg: '5%', xl: 0 }, display: "flex", justifyContent: { xs: "none", md: "space-around" }, width: "100vw", flexDirection: { xs: "column", lg: "row" } }}>
                <Box sx={{ width: { md: "40vw", xs: "100%" } }}>
                    <Typography variant="h4" sx={{ WebkitTextStroke: "1px black", paddingTop: "4rem", textAlign: "center", color: "#00C04A", fontFamily: "'Modak'", fontSize: { md: "4rem", xs: "3rem" }, textShadow: "0.1em 0.1em 0.1em #333" }}>{t("home.minigames")}</Typography>
                    <Typography color="white" sx={{ textAlign: 'center', WebkitTextStroke: "1px black", fontSize: { sm: "1.3rem", xs: "1rem" }, fontFamily: "'Fredoka One', cursive" }}>
                        {t("home.minigames-text")}
                    </Typography>
                </Box>
                <Box sx={{ width: { md: "40vw", xs: "100%" } }}>
                    <Typography variant="h4" sx={{ WebkitTextStroke: "1px black", paddingTop: "4rem", textAlign: "center", color: "#ffa400", fontFamily: "'Modak'", fontSize: { md: "4rem", xs: "3rem" }, textShadow: "0.1em 0.1em 0.1em #333" }}>{t("home.persistent_world")}</Typography>

                    <Typography color="white" sx={{ textAlign: 'center', WebkitTextStroke: "1px black", fontSize: { sm: "1.3rem", xs: "1rem" }, fontFamily: "'Fredoka One', cursive" }}>
                        {t("home.persistent_world-text")}
                    </Typography>
                </Box>
            </Box>
        </Box>
        // <Box className="Lastsection">
        //     <Box className="content" sx={{ position: "relative", height: { xs: '800px', sm: "700px", xl: "789px" }, }} >
        //         <Box sx={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.4 )", width: "100%", height: "100%" }}></Box>
        //         <Box sx={{ display: { xs: "none", md: "flex", height: { xs: '800px', sm: "700px", xl: "789px" }, alignItems: "center", justifyContent: "center" } }}>
        //             <Box
        //                 component="img"
        //                 sx={{
        //                     height: { xs: '800px', sm: "700px", xl: "789px" }
        //                 }}
        //                 alt="Map image"
        //                 src={ggMap}
        //             />
        //         </Box>
        //         <Typography variant="h2" sx={{ position: "absolute", width: "100%", WebkitTextStroke: "1px black", paddingTop: "4rem", textAlign: "center", color: "#00C04A", fontFamily: "'Modak'", fontSize: { md: "5rem", xs: "3rem" }, textShadow: "0.1em 0.1em 0.1em #333" }}>HyperCasual <span style={{ color: "#ffa400" }}>MMO</span></Typography>

        //         <Box sx={{ height: "100%", alignItems: "end", paddingBottom: 11, paddingX: { xs: 3, md: 0 }, position: "absolute", top: { xs: '25%', sm: "10%", md: "0%" }, display: "flex", justifyContent: { xs: "none", md: "space-around" }, width: "100vw", flexDirection: { xs: "column", md: "row" } }}>
        //             <Box sx={{ width: { md: "40vw", xs: "100%" } }}>
        //                 <Typography variant="h4" sx={{ WebkitTextStroke: "1px black", paddingTop: "4rem", textAlign: "center", color: "#00C04A", fontFamily: "'Modak'", fontSize: { md: "5rem", xs: "3rem" }, textShadow: "0.1em 0.1em 0.1em #333" }}>{t("home.minigames")}</Typography>
        //                 <Typography color="white" sx={{ WebkitTextStroke: "1px black", fontSize: { sm: "1.3rem", xs: "1rem" }, fontFamily: "'Fredoka One', cursive" }}>
        //                     {t("home.minigames-text")}
        //                 </Typography>
        //             </Box>
        //             <Box sx={{ width: { md: "40vw", xs: "100%" } }}>
        //                 <Typography variant="h4" sx={{ WebkitTextStroke: "1px black", paddingTop: "4rem", textAlign: "center", color: "#ffa400", fontFamily: "'Modak'", fontSize: { md: "5rem", xs: "3rem" }, textShadow: "0.1em 0.1em 0.1em #333" }}>{t("home.persistent_world")}</Typography>

        //                 <Typography color="white" sx={{ WebkitTextStroke: "1px black", fontSize: { sm: "1.3rem", xs: "1rem" }, fontFamily: "'Fredoka One', cursive" }}>
        //                     {t("home.persistent_world-text")}
        //                 </Typography>
        //             </Box>
        //         </Box>
        //     </Box >
        //     {/* <Typography variant="h2" sx={{ fontWeight: 600, fontSize: { md: "60px", xs: "40px" } }} marginBottom={3} >{t("home.mechanics")}</Typography>
        //         <Grid container>
        //             <Grid item xs display={"flex"} alignItems={"center"} sx={{ width: "500px", mt: 3 }}>
        //                 <Swiper

        //                     slidesPerView={1}
        //                     // onSlideChange={(virtualIndex) => console.log(swiper)}
        //                     onSwiper={(swiper) => console.log(swiper)}
        //                     modules={[Virtual, Navigation]}
        //                     virtual
        //                     navigation={true}
        //                     loop={true}
        //                 >
        //                     {MechanicsItems.map((slideContent, index) => (
        //                         <SwiperSlide key={index} virtualIndex={index}>

        //                             <Box sx={{ width: "90%", display: "flex", alignItems: "center", margin: "0 auto", position: "relative" }}>
        //                                 <Box sx={{ position: "absolute", top: "3%", left: "-0.1%", backgroundColor: "#ffa400", paddingLeft: "8px", paddingRight: "20px", fontSize: "1rem", fontWeight: "500" }}>{slideContent.title}</Box>
        //                                 <img style={{ maxHeight: "100%", maxWidth: "100%" }} src={slideContent.imageUrl} alt="hola vincent" />
        //                             </Box>


        //                         </SwiperSlide>
        //                     ))}

        //                 </Swiper>
        //             </Grid>
        //             <Grid item xs md={5} sx={{ display: "flex", flexDirection: "column" }}>
        //                 <Typography my={4}  >
        //                     {MechanicsDescription}
        //                 </Typography>
        //                 <TButton text={t("home.playNow")} />
        //             </Grid>

        //         </Grid> */}


        // </Box >

    )
}