import { useTranslation } from "react-i18next";
import { MechanicsItem, MinigamesItem } from "../models/Home";
import { Skin } from "../models/Skins";
import back from "../components/img/gg-back1.png"
import caballeroBG from "../components/img/gg-back1.png"

import knigthIcon from "../components/img/knigth-icon.png"
import mutantIcon from "../components/img/mutant-icon.png"
import robotIcon from "../components/img/robot-icon.png"
import dragosaurio from "../components/img/dragosaurio-icon.png"


// Skins import
import dragosauriousRender from "../components/img/Skins/DragosaurioRender1.png"
import dragosauriousAvatar from "../components/img/Skins/DragosaurioRender2.png"

import knightRender from "../components/img/Skins/Knight_render_1.png"
import knightAvatar from "../components/img/Skins/Knight_render_2.png"

import mutantRender from "../components/img/Skins/Mutant_skin_Render.png"
import mutantAvatar from "../components/img/Skins/Mutant_skin_Render2.png"

import robotRender from "../components/img/Skins/RobotSkinRender2.png"
import robotAvatar from "../components/img/Skins/RobotSkinRender3.png"


export const HeroItems = {
    mainIng: "https://tesseractwebresources.blob.core.windows.net/webimages/Gaming/GamesGroundIslandSliderGaming.jpg",
    buttonLink: "",
}
export const SkinsItem: Skin[] = [
    {
        id: "Knigth",
        name: "Caballero",
        imgUrl: knightRender,
        avatar: knightAvatar,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et molestie ac feugiat sed lectus vestibulum mattis. Est sit amet facilisis magna. Suspendisse potenti nullam ac tortor.",
        backgroundImg: caballeroBG
    },
    {
        id: "Mutant",
        name: "Mutante",
        imgUrl: mutantRender,
        avatar: mutantAvatar,
        description: "Loremasfd ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et molestie ac feugiat sed lectus vestibulum mattis. Est sit amet facilisis magna. Suspendisse potenti nullam ac tortor.",
        backgroundImg: "https://images6.alphacoders.com/906/906468.jpg"
    },

    {
        id: "Robot",
        name: "Robotcito",
        imgUrl: robotRender,
        avatar: robotAvatar,
        description: "Lorasdfem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et molestie ac feugiat sed lectus vestibulum mattis. Est sit amet facilisis magna. Suspendisse potenti nullam ac tortor.",
        backgroundImg: "https://www.1zoom.me/big2/63/217237-Sepik.jpg"
    },
    {
        id: "Dragosaur",
        name: "Dragosaurio",
        imgUrl: dragosauriousRender,
        avatar: dragosauriousAvatar,
        description: "Lorem iasdfpsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et molestie ac feugiat sed lectus vestibulum mattis. Est sit amet facilisis magna. Suspendisse potenti nullam ac tortor.",
        backgroundImg: "https://images.pling.com/img/00/00/61/26/90/1556655/30f31321f4875bd058a95430340a5ba82b9485a0d5c4824354cd2c4ceb19176318ce.jpg"

    },
];

export const MinigamesDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempus id lectus vestibulum hendrerit. Vivamus feugiat efficitur nibh vitae suscipit. Suspendisse semper eleifend nisl, dignissim congue lectus vehicula ut. Morbi porttitor risus id vulputate venenatis. Morbi rutrum, odio a vulputate porttitor, nunc lectus blandit nibh, ac varius lacus justo eu nisi. Nam in nulla nec augue placerat lobortis eu sed sem. Donec ac massa a velit imperdiet lacinia ac vel orci. Maecenas feugiat, quam ac luctus semper, diam augue ornare massa, at facilisis odio ligula vitae eros. Donec et tristique leo, non rutrum dolor."
export const MiniGamesItems: MinigamesItem[] = [
    {
        title: "PINTADA",
        imageUrl: "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_LeagueofLegends_RiotGames_S1_2560x1440-ee500721c06da3ec1e5535a88588c77f"
    }, {
        title: "Nose",
        imageUrl: "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_LeagueofLegends_RiotGames_S1_2560x1440-ee500721c06da3ec1e5535a88588c77f"
    }, {
        title: "Aca tampoco se",
        imageUrl: "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_LeagueofLegends_RiotGames_S1_2560x1440-ee500721c06da3ec1e5535a88588c77f"
    }
]

export const MechanicsDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempus id lectus vestibulum hendrerit. Vivamus feugiat efficitur nibh vitae suscipit. Suspendisse semper eleifend nisl, dignissim congue lectus vehicula ut. Morbi porttitor risus id vulputate venenatis. Morbi rutrum, odio a vulputate porttitor, nunc lectus blandit nibh, ac varius lacus justo eu nisi. Nam in nulla nec augue placerat lobortis eu sed sem. Donec ac massa a velit imperdiet lacinia ac vel orci. Maecenas feugiat, quam ac luctus semper, diam augue ornare massa, at facilisis odio ligula vitae eros. Donec et tristique leo, non rutrum dolor."
export const MechanicsItems: MechanicsItem[] = [
    {
        title: "Caida",
        imageUrl: "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_LeagueofLegends_RiotGames_S1_2560x1440-ee500721c06da3ec1e5535a88588c77f"
    }, {
        title: "Nose",
        imageUrl: "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_LeagueofLegends_RiotGames_S1_2560x1440-ee500721c06da3ec1e5535a88588c77f"
    }, {
        title: "Aca tampoco se",
        imageUrl: "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_LeagueofLegends_RiotGames_S1_2560x1440-ee500721c06da3ec1e5535a88588c77f"
    }
]