import { Card, CardMedia } from "@mui/material"


export const  ImageCard = ({imagen}: any) => {

  return (
    <Card sx={{ maxWidth: 380, maxHeight: 200, marginBottom: 2}}>
        <CardMedia
          component="img"
          image={imagen.img}
          alt="hola vincent"
        />
    </Card>
  )
}
