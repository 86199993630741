import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './routers/AppRouter';

function App() {
  const auth = useAuth();
  useEffect(() => {
    console.log(auth.user);
  }, [auth])

  return (
    <BrowserRouter >
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
