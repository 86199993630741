import GGMap1 from "../components/img/GG_mapa_1.png"
import GGMap2 from "../components/img/GG_mapa_2.png"
import village from "../components/img/caballero-bg.png"
import caves from "../components/img/caves-bg.png"
import GGBack1 from "../components/img/gg-back1.png"
import GGBack2 from "../components/img/gg-back2.png"
import GGBack3 from "../components/img/gg.back3.jpg"


export const GalleryObj = {
    imageList: [
        {
            img: GGMap1,
            title: 'GGMap1',
        },
        {
            img: GGMap2,
            title: 'GGMap2',
        },
        {
            img: village,
            title: 'Village',
        },
        {
            img: caves,
            title: 'Caves',
        },
        {
            img: GGBack1,
            title: 'GG Background 1',
        },
        {
            img: GGBack2,
            title: 'GG Background 2',
        },
        {
            img: GGBack3,
            title: 'GG halloween village',
        },
        // {
        //     img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        //     title: 'Fern',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        //     title: 'Mushrooms',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        //     title: 'Tomato basil',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        //     title: 'Sea star',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        //     title: 'Bike13',
        // },
        // {
        //     img: 'https://i.ytimg.com/vi/LZCUgsNrDOQ/maxresdefault.jpg',
        //     title: 'Bike12',
        // },
        // {
        //     img: 'https://i.ytimg.com/vi/LZCUgsNrDOQ/maxresdefault.jpg',
        //     title: 'Bike0',
        // },
        // {
        //     img: 'https://i.ytimg.com/vi/LZCUgsNrDOQ/maxresdefault.jpg',
        //     title: 'Bike11',
        // },
        // {
        //     img: 'https://i.ytimg.com/vi/LZCUgsNrDOQ/maxresdefault.jpg',
        //     title: 'Bike9',
        // },
        // {
        //     img: 'https://i.ytimg.com/vi/LZCUgsNrDOQ/maxresdefault.jpg',
        //     title: 'Bike8',
        // },
        // {
        //     img: 'https://i.ytimg.com/vi/LZCUgsNrDOQ/maxresdefault.jpg',
        //     title: 'Bike7',
        // }
    ],
    videoList: [
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Breakfast',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Burger',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Camera',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Coffee',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Hats',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Honey',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Basketball',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Fern',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Mushrooms',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Tomato basil',
        },
        {
            video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            title: 'Sea star',
        }
    ],
    trailer: "https://www.youtube.com/embed/b1b8uvPbTpY"
}