
import { Divider, Grid, Grow, IconButton, Pagination, Select, Slide, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ImageCard } from '../components/MediaGallery/ImageCard';
import { ImageItem, VideoItem } from '../models/Gallery';
import { GalleryObj } from "../objects/GalleryObj"
import { useTranslation } from 'react-i18next';
import caballeroBG from "../components/img/caballero-bg.png"
import bghallo from "../components/img/gg.back3.jpg"

const itemData = GalleryObj.imageList

const videoData = GalleryObj.videoList

export const MediaGallery = () => {

    const { t } = useTranslation()

    //pagination components
    const [page, setPage] = useState(0);
    const [page2, setPage2] = useState(0);
    const [pageVideo, setPageVideo] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [itemsPerPage2, setItemsPerPage2] = useState(3);
    const [videoPerPage, setVideoPerPage] = useState(1);

    //pagination configuration
    const handleChangePage = (actualPage: any) => {
        const newPage = actualPage + page;
        if (newPage >= 0 && newPage < (itemData.length / itemsPerPage)) {
            setPage(newPage);
        }
    };

    const handleSelectPage = (event: unknown, newPage: number) => {
        setPage(newPage - 1);
    };

    const handleChangePage2 = (actualPage: any) => {
        const newPage = actualPage + page2;
        if (newPage >= 0 && newPage < (itemData.length / itemsPerPage2)) {
            setPage2(newPage);
        }
    };

    const handleChangePageVideo = (actualPage: any) => {
        const newPage = actualPage + pageVideo;
        if (newPage >= 0 && newPage < (videoData.length / videoPerPage)) {
            setPageVideo(newPage);
        }
    };

    const handleSelectPageVideo = (event: unknown, newPage: number) => {
        setPageVideo(newPage - 1);
    };

    return (
        <Box>
            <Box className="SectionsWithDividerztree" >
                <Box sx={{ position: "relative", height: { xl: 800, lg: 800, xs: 800 }, backgroundColor: "#231f20" }}>
                    {/* <img src={caballeroBG}
                        style={{
                            // width: "120%",
                            maxHeight: "1000px",
                            minHeight: "300px",
                            filter: " blur(20px)"
                        }} /> */}
                    <Box sx={{ position: "fixed", height: "100%", zIndex: 1, left: 0, right: 0, backgroundImage: `url(${caballeroBG})`, filter: " blur(20px)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}></Box>
                    <Box sx={{ position: "absolute", zIndex: 9999, height: "100%", left: 0, right: 0 }}>
                        <Box display="flex"
                            justifyContent="center"
                            padding="24px"
                            paddingTop={15}>
                            <iframe
                                id="video"
                                src={GalleryObj.trailer}
                                width="1000"
                                height="500"
                                frameBorder="0"
                                allowFullScreen
                                style={{
                                    borderRadius: "8px"
                                }}
                            />
                        </Box>
                        {/* <Grid container paddingY={10}>
                            <Grid item xs
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ArrowBackIosNewIcon
                                    id="GamesGalleryArrowBack"
                                    onClick={() => { handleChangePage2(-1) }}
                                    fontSize="large"
                                    onMouseEnter={() => {
                                        const docu = document.getElementById("GamesGalleryArrowBack") as HTMLElement | null;
                                        if (docu !== null && page2 > 0) {
                                            docu.style.borderColor = "#ffa400";
                                            docu.style.backgroundColor = "#F7F7F7"
                                        }

                                    }}
                                    onMouseLeave={() => {
                                        const docu = document.getElementById("GamesGalleryArrowBack") as HTMLElement | null;
                                        if (docu !== null) {
                                            docu.style.borderColor = "#FFD78E";
                                            docu.style.backgroundColor = "white"
                                        }
                                    }}
                                    sx={{
                                        cursor: (page2 > 0) ? "pointer" : "default",
                                        padding: "0.5rem",
                                        borderRadius: "100%",
                                        border: "2px solid #FFD78E",
                                        color: (page2 > 0) ? "black" : "#DEDEDE"
                                    }} />
                            </Grid>

                            <Grid item
                                display="flex"
                                xs={9}
                                sx={{ width: "100%", maxWidth: 1240 }}>
                                <Grid container width={"100%"} display="flex" justifyContent={"center"} alignItems="center" padding={2}>
                                    {
                                        itemData.slice(page2 * itemsPerPage2, page2 * itemsPerPage2 + itemsPerPage2).map((image) => (
                                            <Grid key={image.title} item p={2}>
                                                <ImageCard imagen={image} alt="hola vincent" />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>


                            <Grid item xs
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <ArrowForwardIosIcon
                                    id="GamesGalleryArrowForward"
                                    fontSize="large"
                                    onClick={() => { handleChangePage2(1) }}
                                    onMouseEnter={() => {
                                        const docu = document.getElementById("GamesGalleryArrowForward") as HTMLElement | null;
                                        if (docu !== null && page2 < (itemData.length / itemsPerPage2) - 1) {
                                            docu.style.borderColor = "#ffa400";
                                            docu.style.backgroundColor = "#F7F7F7"
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        const docu = document.getElementById("GamesGalleryArrowForward") as HTMLElement | null;
                                        if (docu !== null) {
                                            docu.style.borderColor = "#FFD78E";
                                            docu.style.backgroundColor = "white"
                                        }

                                    }}
                                    sx={{
                                        cursor: (page2 < (itemData.length / itemsPerPage2) - 1) ? "pointer" : "default",
                                        padding: "0.5rem",
                                        borderRadius: "100%",
                                        border: "2px solid #FFD78E",
                                        color: (page2 < (itemData.length / itemsPerPage2) - 1) ? "black" : "#DEDEDE"
                                    }} />
                            </Grid>
                        </Grid> */}
                    </Box>
                </Box>

            </Box>
            <div className="SectionsWithDividerzfor whiteLine" />
            {/* <Box className="SectionsWithDividerzfive" sx={{ backgroundColor: "#231f20" }} >
                <Box sx={{ paddingY: "80px" }}>
                    <Grid container marginTop={5} >
                        <Grid item xs
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <ArrowBackIosNewIcon
                                id="VideoGalleryArrowBack"
                                fontSize="large"
                                onClick={() => { handleChangePageVideo(-1) }}
                                onMouseEnter={() => {
                                    const docu = document.getElementById("VideoGalleryArrowBack") as HTMLElement | null;
                                    if (docu !== null && pageVideo > 0) {
                                        docu.style.borderColor = "#ffa400";
                                        docu.style.backgroundColor = "#F7F7F7"
                                    }

                                }}
                                onMouseLeave={() => {
                                    const docu = document.getElementById("VideoGalleryArrowBack") as HTMLElement | null;
                                    if (docu !== null) {
                                        docu.style.borderColor = "#FFD78E";
                                        docu.style.backgroundColor = "white"
                                    }

                                }}
                                sx={{
                                    cursor: (pageVideo > 0) ? "pointer" : "default",
                                    padding: "0.5rem",
                                    borderRadius: "100%",
                                    border: "2px solid #FFD78E",
                                    color: (pageVideo > 0) ? "black" : "#DEDEDE"
                                }} />
                        </Grid>

                        <Grid item xs={9}
                            display="flex"
                            justifyContent={"center"}
                            alignItems="center"
                        >
                            {
                                videoData.slice(pageVideo * videoPerPage, pageVideo * videoPerPage + videoPerPage).map((video) => (
                                    <iframe
                                        id="video"
                                        src={video.video}
                                        width="100%"
                                        height="500"
                                        frameBorder="0"
                                        allowFullScreen
                                        style={{
                                            borderRadius: "8px",
                                        }}
                                    />
                                ))

                            }
                        </Grid>

                        <Grid item xs
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <ArrowForwardIosIcon
                                id="VideoGalleryArrowForward"
                                fontSize="large"
                                onClick={() => { handleChangePageVideo(1) }}
                                onMouseEnter={() => {
                                    const docu = document.getElementById("VideoGalleryArrowForward") as HTMLElement | null;
                                    if (docu !== null && pageVideo < (videoData.length / videoPerPage) - 1) {
                                        docu.style.borderColor = "#ffa400";
                                        docu.style.backgroundColor = "#F7F7F7"
                                    }
                                }}
                                onMouseLeave={() => {
                                    const docu = document.getElementById("VideoGalleryArrowForward") as HTMLElement | null;
                                    if (docu !== null) {
                                        docu.style.borderColor = "#FFD78E";
                                        docu.style.backgroundColor = "white"
                                    }
                                }}
                                sx={{
                                    cursor: (pageVideo < (videoData.length / videoPerPage) - 1) ? "pointer" : "default",
                                    padding: "0.5rem",
                                    borderRadius: "100%",
                                    border: "2px solid #FFD78E",
                                    color: (pageVideo < (videoData.length / videoPerPage) - 1) ? "black" : "#DEDEDE"
                                }} />
                        </Grid>
                    </Grid>
                    <Pagination
                        count={(videoData.length / videoPerPage)}
                        page={pageVideo + 1}
                        onChange={handleSelectPageVideo}
                        variant="outlined"
                        hideNextButton
                        hidePrevButton
                        size='small'
                        sx={{
                            my: 3,
                            "& .MuiPaginationItem-root": {
                                borderColor: "#black",
                                color: "black",
                                '&.Mui-selected': {
                                    background: '#ffa400',
                                    color: 'black'
                                },
                            },
                            "& .MuiPagination-ul": {
                                justifyContent: "center",
                            }
                        }} />
                </Box>

            </Box> */}
            {/* <div className="SectionsWithDividerztsix whiteLine" /> */}
            <Box className="Lastsection" sx={{ backgroundColor: "#231f20", }} >
                <Box sx={{ position: "relative", height: { xl: 1000, lg: 1350, xs: 1500 }, backgroundColor: "#231f20" }}>

                    <Box sx={{ position: "absolute", height: "100%", zIndex: 1, left: 0, right: 0, backgroundImage: `url(${bghallo})`, filter: " blur(20px)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}></Box>
                    {/* <Box sx={{ position: "absolute", zIndex: 9999, height: "100%", left: 0, right: 0 }}>
                        <Box display="flex"
                            justifyContent="center"
                            padding="24px"
                            paddingTop={15}>
                            <iframe
                                id="video"
                                src={GalleryObj.trailer}
                                width="1000"
                                height="500"
                                frameBorder="0"
                                allowFullScreen
                                style={{
                                    borderRadius: "8px"
                                }}
                            />
                        </Box>
                        <Grid container paddingY={10}>
                            <Grid item xs
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ArrowBackIosNewIcon
                                    id="GamesGalleryArrowBack"
                                    onClick={() => { handleChangePage2(-1) }}
                                    fontSize="large"
                                    onMouseEnter={() => {
                                        const docu = document.getElementById("GamesGalleryArrowBack") as HTMLElement | null;
                                        if (docu !== null && page2 > 0) {
                                            docu.style.borderColor = "#ffa400";
                                            docu.style.backgroundColor = "#F7F7F7"
                                        }

                                    }}
                                    onMouseLeave={() => {
                                        const docu = document.getElementById("GamesGalleryArrowBack") as HTMLElement | null;
                                        if (docu !== null) {
                                            docu.style.borderColor = "#FFD78E";
                                            docu.style.backgroundColor = "white"
                                        }
                                    }}
                                    sx={{
                                        cursor: (page2 > 0) ? "pointer" : "default",
                                        padding: "0.5rem",
                                        borderRadius: "100%",
                                        border: "2px solid #FFD78E",
                                        color: (page2 > 0) ? "black" : "#DEDEDE"
                                    }} />
                            </Grid>

                            <Grid item
                                display="flex"
                                xs={9}
                                sx={{ width: "100%", maxWidth: 1240 }}>
                                <Grid container width={"100%"} display="flex" justifyContent={"center"} alignItems="center" padding={2}>
                                    {
                                        itemData.slice(page2 * itemsPerPage2, page2 * itemsPerPage2 + itemsPerPage2).map((image) => (
                                            <Grid key={image.title} item p={2}>
                                                <ImageCard imagen={image} alt="hola vincent" />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>


                            <Grid item xs
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <ArrowForwardIosIcon
                                    id="GamesGalleryArrowForward"
                                    fontSize="large"
                                    onClick={() => { handleChangePage2(1) }}
                                    onMouseEnter={() => {
                                        const docu = document.getElementById("GamesGalleryArrowForward") as HTMLElement | null;
                                        if (docu !== null && page2 < (itemData.length / itemsPerPage2) - 1) {
                                            docu.style.borderColor = "#ffa400";
                                            docu.style.backgroundColor = "#F7F7F7"
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        const docu = document.getElementById("GamesGalleryArrowForward") as HTMLElement | null;
                                        if (docu !== null) {
                                            docu.style.borderColor = "#FFD78E";
                                            docu.style.backgroundColor = "white"
                                        }

                                    }}
                                    sx={{
                                        cursor: (page2 < (itemData.length / itemsPerPage2) - 1) ? "pointer" : "default",
                                        padding: "0.5rem",
                                        borderRadius: "100%",
                                        border: "2px solid #FFD78E",
                                        color: (page2 < (itemData.length / itemsPerPage2) - 1) ? "black" : "#DEDEDE"
                                    }} />
                            </Grid>
                        </Grid>
                    </Box> */}
                    <Box sx={{ position: "absolute", zIndex: 9999, height: "100%", left: 0, right: 0, top: "10%" }}>
                        <Typography variant="h3" p={3} style={{ color: "#fff", fontWeight: 700, fontSize: "3rem" }}>
                            Community
                        </Typography>
                        <Grid container >
                            <Grid item xs
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ArrowBackIosNewIcon
                                    id="GamesGalleryArrowBack"
                                    onClick={() => { handleChangePage(-1) }}
                                    fontSize="large"
                                    onMouseEnter={() => {
                                        const docu = document.getElementById("GamesGalleryArrowBack") as HTMLElement | null;
                                        if (docu !== null && page > 0) {
                                            docu.style.borderColor = "#ffa400";
                                            docu.style.backgroundColor = "#F7F7F7"
                                        }

                                    }}
                                    onMouseLeave={() => {
                                        const docu = document.getElementById("GamesGalleryArrowBack") as HTMLElement | null;
                                        if (docu !== null) {
                                            docu.style.borderColor = "#FFD78E";
                                            docu.style.backgroundColor = "white"
                                        }
                                    }}
                                    sx={{
                                        cursor: (page > 0) ? "pointer" : "default",
                                        padding: "0.5rem",
                                        borderRadius: "100%",
                                        border: "2px solid #FFD78E",
                                        color: (page > 0) ? "black" : "#DEDEDE"
                                    }} />
                            </Grid>

                            <Grid item
                                display="flex"
                                xs={9}
                                sx={{ width: "100%", maxWidth: 1240 }}>
                                <Grid container width={"100%"} display="flex" justifyContent={"center"} alignItems="center">
                                    {
                                        itemData.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map((image) => (
                                            <Grid key={image.title} item p={1}>
                                                <ImageCard imagen={image} alt="hola vincent" />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>


                            <Grid item xs
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <ArrowForwardIosIcon
                                    id="GamesGalleryArrowForward"
                                    fontSize="large"
                                    onClick={() => { handleChangePage(1) }}
                                    onMouseEnter={() => {
                                        const docu = document.getElementById("GamesGalleryArrowForward") as HTMLElement | null;
                                        if (docu !== null && page < (itemData.length / itemsPerPage) - 1) {
                                            docu.style.borderColor = "#ffa400";
                                            docu.style.backgroundColor = "#F7F7F7"
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        const docu = document.getElementById("GamesGalleryArrowForward") as HTMLElement | null;
                                        if (docu !== null) {
                                            docu.style.borderColor = "#FFD78E";
                                            docu.style.backgroundColor = "white"
                                        }

                                    }}
                                    sx={{
                                        cursor: (page < (itemData.length / itemsPerPage) - 1) ? "pointer" : "default",
                                        padding: "0.5rem",
                                        borderRadius: "100%",
                                        border: "2px solid #FFD78E",
                                        color: (page < (itemData.length / itemsPerPage) - 1) ? "black" : "#DEDEDE"
                                    }} />
                            </Grid>
                        </Grid>

                        <Grid marginBottom="5rem">
                            <Pagination
                                count={(itemData.length / itemsPerPage)}
                                page={page + 1}
                                onChange={handleSelectPage}
                                variant="outlined"
                                hideNextButton
                                hidePrevButton
                                size='small'
                                sx={{
                                    my: 3,
                                    "& .MuiPaginationItem-root": {
                                        borderColor: "#black",
                                        color: "black",
                                        '&.Mui-selected': {
                                            background: '#ffa400',
                                            color: 'black'
                                        },
                                    },
                                    "& .MuiPagination-ul": {
                                        justifyContent: "center",
                                    }
                                }} />
                        </Grid>
                    </Box>
                </Box>


            </Box>
        </Box >
    )
}
