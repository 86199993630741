import axios, { AxiosResponse } from 'axios';

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}


axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
        config.headers!['Authorization'] = `Bearer ${token}`
    }
    return config
})

axios.interceptors.response.use(async response => {
    try {
        await sleep(1000);
        return response;
    } catch (error) {
        return await Promise.reject(error);
    }
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

const Accounts = {
    current: () => requests.get<any>(`${process.env.REACT_APP_CONTENTMANAGER_URL}/api/auth/verify`),
    getPhoto: (id: string) => requests.get<any>(`${process.env.REACT_APP_TAUTH_URL}/api/account/getuserinformation?Id=${id}`),
    login: () => requests.get<any>(`${process.env.REACT_APP_CONTENTMANAGER_URL}/api/auth/login`),
}

const agent = {
    Accounts
}

export default agent;