import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { Path } from "../../../models/Paths"
// import "../styles/CSS/Home.css"


export const NavRoutes = () => {
    const { t, i18n } = useTranslation()
    const actualRoute = useLocation()
    const routes: Path[] = [
        {
            path: `/${i18n.language}`,
            title: t("Navbar.home")
        },
        {
            path: `/${i18n.language}/gallery`,
            title: t("Navbar.gallery")
        }
    ]
    return (
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {
                routes.map((route) => {
                    return (
                        <Link to={route.path} className={`navItem ${actualRoute.pathname === route.path ? "active" : ""} `}>
                            <Typography variant="h6" component="div" sx={{ fontSize: "1rem !important", color: "#fff" }} >
                                {(route.title).toUpperCase()}
                            </Typography>
                        </Link>
                    )
                })
            }

        </Box>
    )
}
