import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { checkingCredentials, login, logout } from "../auth/authSlice";
import { User, UserAuth, UserFormValues, UserRegister } from "../models/User";
import Swal from "sweetalert2";
import authAgent from "./authAgent";
import { parseJwt } from "../../functions/DecodeJWT";


export const checkingAuth = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
    }
}

export const verifyToken = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
        authAgent.Accounts.current()
            .then((result) => {

                const token = window.localStorage.getItem("jwt") as string;
                const user = parseJwt(token)
                authAgent.Accounts.getPhoto(user.uid)
                    .then((result) => {
                        dispatch(login({ ...user, profileImage: result.profilePicture }));
                    }).catch((err) => {
                        dispatch(login({ ...user }));
                    });
            }).catch((err) => {
                console.log(err)
                dispatch(logout());
            });
    }
}

export const loginAuth = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
        authAgent.Accounts.login()
            .then((result) => {
                console.log(result)
            }).catch((err) => {
                console.log(err)
                window.location.href = `${process.env.REACT_APP_TAUTHWEB}/?url=${window.location.origin}`
            });
    }
}


export const startLoginWithEmailPassword = (loginUser: UserFormValues): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {

        dispatch(checkingCredentials());

        // await authAgent.Accounts.login(loginUser)
        //     .then((response) => {
        //         dispatch(login(response));
        //     })
        //     .catch((error) => {
        //         dispatch(logout())
        //         Swal.fire("Error", "Credenciales no encontradas", "error");
        //     })
    }
}

export const setUser = (user: UserAuth): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(login(user));
    }
}

export const startLogout = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
        dispatch(logout())
    }
}