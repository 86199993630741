import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import './translations/i18n';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const oidcConfig = {
  authority: process.env?.REACT_APP_AUTHORITY as string,
  client_id: process.env?.REACT_APP_CLIENT_ID as string,
  redirect_uri: process.env?.REACT_APP_REDIRECT_URI as string,
  response_type: process.env?.REACT_APP_RESPONSE_TYPE as string,
  scope: process.env?.REACT_APP_SCOPE as string,
  post_logout_redirect_uri: process.env?.REACT_APP_POSTURI as string,
  client_secret: process.env?.REACT_APP_CLIENT_SECRET as string,
  // response_mode: "query",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

root.render(
  <Provider store={store}>
    <AuthProvider {...oidcConfig}>
      <Suspense fallback={
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
          <span className="loader"></span>
        </div>
      }>
        <App />
      </Suspense>
    </AuthProvider>

  </Provider>
);

