import { Avatar, Box, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { Skin } from "../../../models/Skins"

interface props {
    skins: Skin[]
    OnSkin: (arg: string) => void

}
export const SkinsSelector = ({ skins, OnSkin }: props) => {
    const [currentSkin, setCurrentSkin] = useState("0")
    const onSelect = (event: React.MouseEvent<HTMLDivElement>) => {
        setCurrentSkin(event.currentTarget.id)
        OnSkin(event.currentTarget.id)
    }
    return (
        <Grid className="SkinsListContainer" sx={{ display: "flex", flexDirection: { xs: "row", md: "column" }, justifyContent: "center", paddingX: 0 }}>

            {
                skins.map((skin, index) => {
                    return (
                        <Box className={`SkinsListItem ${currentSkin === `${index}` ? "selected" : ""}`} id={`${index}`} onClick={onSelect} component={"li"} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Avatar className="avatarSkin" alt="Skin" src={skin.avatar} sx={{
                                width: { xs: "3rem", md: "4rem" },
                                height: { xs: "3rem", md: "4rem" },
                            }} />
                        </Box>
                    )

                })
            }
        </Grid>
    )
}

