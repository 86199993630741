import { Avatar, Box, Button, Divider, Grid, ThemeProvider, Typography } from "@mui/material"
import "../../../styles/CSS/Home.css"
import "../../../styles/CSS/Diagonal.css"
import { useEffect, useState } from "react";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import { Skin } from "../../../models/Skins";
import { SkinsSelector } from "./SkinSelector";
import { SkinsItem } from "../../../objects/HomeObj";
import SkinMove from "./Move/app";
import { useTranslation } from "react-i18next";
import { height } from "@mui/system";
import backImg from "../../img/gg-back1.png"
import caballeroBG from "../../img/caballero-bg.png"



export const SkinSection = () => {
    const { t } = useTranslation()
    const [currentSkin, setCurrentSkin] = useState("0")

    const OnSkin = (index: string) => {
        setCurrentSkin(index)
    }
    return (
        <Box className="SectionsWithDividerzfive" sx={{ backgroundColor: "#231f20", paddingBottom: "80px", maxHeight: "900px" }}>
            <Box position={"relative"} className="content" sx={{ overflow: "hidden" }}>
                <img src={caballeroBG}
                    style={{
                        // width: "120%",
                        maxHeight: "1000px",
                        minHeight: "300px",
                        height: "900px",
                        filter: "blur(8px)"
                    }} />
                <Box sx={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.4 )", width: "100%", height: "1000px", top: "0%" }}></Box>
                <Box sx={{ position: "absolute", top: "10%", left: "50%", transform: "translate(-50%,-5%)", display: "flex", flexDirection: "column", alignItems: "center" }}>{/*Title*/}
                    {/* <Typography variant="h2" sx={{ fontWeight: 700, fontSize: { md: "4rem", xs: "1.3rem" }, color: "white" }}>GG Characters</Typography> */}
                    {/* <img src={ggLogo} style={{ width: "150px" }} /> */}
                    <Typography variant="h2" sx={{ WebkitTextStroke: "1px black", color: "#00C04A", fontFamily: "'Modak'", fontSize: { md: "2.4rem", xs: "1.8rem" }, textShadow: "0.1em 0.1em 0.1em #333" }}>{t("home.subtitleSkins")}</Typography>
                    <Typography variant="h2" sx={{ WebkitTextStroke: "1px black", color: "#ffa400", fontFamily: "'Modak'", fontSize: { md: "4rem", xs: "3.3rem" }, textShadow: "0.1em 0.1em 0.1em #333" }}>{t("home.titleSkins")}</Typography>

                </Box>
                <Box sx={{ position: "absolute", top: { md: "25%", xs: "18%" }, left: "0%", zIndex: 1000, width: { xs: "100%", md: "auto" } }}>
                    <SkinsSelector skins={SkinsItem} OnSkin={OnSkin} />
                </Box>

                <Grid container direction={"row"} justifyContent="center" position={"absolute"} sx={{ display: { md: "flex", xs: "none" }, padding: 3, height: "100%", position: "absolute", top: { md: "30%", xs: "15%" }, transform: { md: "translate(-10%)", xs: "translate(0%)" }, }}>
                    <Grid item xs={8}>
                        <SkinMove skin={SkinsItem[+currentSkin]} />
                    </Grid>
                    <Grid item xs sx={{ display: { md: "block", xs: "none" } }}>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography py={3} variant="h3" sx={{ WebkitTextStroke: "1px black", color: "#00C04A", display: { md: "block", xs: "none" }, fontFamily: "'Modak'", textShadow: "0.1em 0.1em 0.1em #333" }} color="white">{t("skins.name" + SkinsItem[+currentSkin].id)}</Typography>
                            <Typography color="white" sx={{ WebkitTextStroke: "1px black", display: { md: "block", xs: "block" }, fontSize: "1.5rem", fontFamily: "'Fredoka One', cursive" }}>
                                {t("skins.descr" + SkinsItem[+currentSkin].id)}
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>
                <Box justifyContent="center" position={"absolute"} sx={{ display: { md: "none", xs: "flex" }, padding: 3, height: "100%", top: { md: "50%", xs: "20%" }, transform: { md: "translate(-10%)", xs: "translate(0%)" }, flexDirection: "column" }}>
                    <Box position={"relative"} top={"-18%"}>
                        <SkinMove skin={SkinsItem[+currentSkin]} />
                    </Box>
                    <Box >
                        <Box sx={{ textAlign: "center" }}>
                            <Typography py={3} variant="h3" sx={{ WebkitTextStroke: "1px black", color: "#00C04A", fontFamily: "'Modak'", textShadow: "0.1em 0.1em 0.1em #333" }} color="white">{t("skins.name" + SkinsItem[+currentSkin].id)}</Typography>
                            <Typography color="white" sx={{ WebkitTextStroke: "1px black", fontSize: "1.5rem", fontFamily: "'Fredoka One', cursive" }}>
                                {t("skins.descr" + SkinsItem[+currentSkin].id)}
                            </Typography>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box >
    )
}

