import { configureStore } from '@reduxjs/toolkit'
import { newsSlice } from './news/newsSlice'
import { authSlice } from './auth/authSlice'



export const store = configureStore({
  reducer: {
    news: newsSlice.reducer,
    auth: authSlice.reducer
    /*home: homeSlice.reducer,
    aboutUs: aboutSlice.reducer,
    faq: faqSlice.reducer,
    */
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch