import { Api, Code, ConnectWithoutContactTwoTone, Home, Language, Money, NetworkCellRounded, Newspaper, Paid, People, Phone, PhoneAndroid, TableBar, TableChart, Web, Work, } from "@mui/icons-material";
import { Link as MaterialLink, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom"
// import GroupAddIcon from '@mui/icons-material/GroupAdd';
import React from "react";
import TListItem from "./TListItem";
// import { useAppSelector } from "../../../hooks/redux-hooks";
// import { LineChartOutlined } from "@ant-design/icons";
import CollectionsIcon from '@mui/icons-material/Collections';
import HomeIcon from '@mui/icons-material/Home';    
import { useTranslation } from "react-i18next";

export default function TDrawerItemsList(props: any) {
    const location = useLocation();
    const { t, i18n } = useTranslation()
    // const auth = useAppSelector(state => state.auth)

    return (
        <React.Fragment>
            <TListItem title={t("Navbar.home")} link={`/${i18n.language}`} icon={<HomeIcon sx={{ color: "#fff" }} />} />
            <TListItem title={t("Navbar.gallery")} link={`/${i18n.language}/gallery`} icon={<CollectionsIcon sx={{ color: "#fff" }} />} />

            {/* {
                auth.role === "Admin" && <TListItem title={"Register User"} link={"/register"} icon={<GroupAddIcon />} />
            } */}
        </React.Fragment>
    );
}