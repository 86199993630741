import { Box, Button, Divider, Grid, ThemeProvider, Typography } from "@mui/material"
import "../styles/CSS/Home.css"
import { appTheme } from "../styles/MUI/Theme"
import { useEffect, useState } from "react";
import { TButton } from "../components/CustomComponents/TesseractButton";
import { Hero } from "../components/Home/Hero";
import { LastNews } from "../components/Home/LastNewsSection";
import { Skin } from "../models/Skins";
import { SkinSection } from "../components/Home/SkinsSection/Main";
import { Minigames } from "../components/Home/MiniGamesSection/Main";
import { Mechanics } from "../components/Home/MechanicsSections/Main";
import { DiscordCallToAction } from "../components/Home/DiscordCalltoAction/Main";
import { DownloadSection } from "../components/Home/DownloadSection";




export const HomePage = () => {

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [currentSkin, setCurrentSkin] = useState("0")
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const newMainSize = 500
    const OnSkin = (index: string) => {
        setCurrentSkin(index)
    }

    return (
        <ThemeProvider theme={appTheme}>
            <Hero />
            {/* <LastNews /> */}
            <div className="SectionsWithDividerztwo whiteLine" />
            {/* <DownloadSection />
            <div className="SectionsWithDividerzfor whiteLine" /> */}

            <DiscordCallToAction />
            <div className="SectionsWithDividerzfor whiteLine" />

            <SkinSection />
            {/* <div className="SectionsWithDividerztwo whiteLine" /> */}
            {/* <DiscordCallToAction /> */}
            {/* <div className="SectionsWithDividerzfor whiteLine" /> */}

            {/* <Minigames />*/}
            <div className="SectionsWithDividerztsix whiteLine" />
            <Mechanics />


        </ThemeProvider >
    )
}
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
