import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { FaDiscord } from "react-icons/fa";

export const DiscordCallToAction = () => {
    const { t } = useTranslation()

    return (
        <Box className="SectionsWithDividerztree" sx={{ backgroundColor: "#231f20", maxHeight: "900px" }}>
            <Box position={"relative"} className="content" sx={{ overflow: "hidden", height: { xs: "800px", lg: "100%" } }}>
                <img src={require("../../img/gg-back1.png")}
                    style={{
                        width: "100%",
                        maxHeight: "1000px",
                        minHeight: "300px",
                        height: "100%",
                        filter: "blur(8px)"
                    }} />
                <Box sx={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.4 )", width: "100%", height: "1000px", top: "0%" }}></Box>
                <Box sx={{ position: "absolute", top: "0%", left: "0%", alignItems: 'center', width: "100%", height: "100%", justifyContent: 'center', display: 'flex', flexDirection: "column", paddingX: { xs: 3 } }}>

                    <Typography variant='h2' fontWeight={'bold'} marginY={3} textAlign={'center'} sx={{ WebkitTextStroke: "1px black", color: "#00C04A", display: { md: "block", xs: "none" }, fontFamily: "'Modak'", textShadow: "0.1em 0.1em 0.1em #333" }}>
                        {t("home.discordCallToAction.title")}
                    </Typography>
                    <a target={"_blank"} href={"https://discord.gg/7WvFQdkg96"} style={{ textDecoration: "none" }}>
                        <Box sx={{
                            backgroundColor: "#5865F2", borderRadius: 4, display: 'flex', gap: { xs: 1, sm: 3 }, alignItems: 'center', padding: 2, flexDirection: { xs: "column", sm: "row" },
                            "&:hover": {
                                cursor: "pointer",
                                backgroundColor: "rgba(88, 101, 242,0.9)"
                            }
                        }}>
                            <FaDiscord fontSize={70} color={"#fff"} />
                            <Box sx={{ display: 'flex', flexDirection: "column", textAlign: 'center' }}>
                                {/* <Typography variant='h5' fontWeight={"bold"} color={"#fff"} >
                                    {t("home.discordCallToAction.button")}
                                </Typography> */}
                                <Typography variant='h3' fontWeight={"bold"} color={"#fff"} >
                                    DISCORD
                                </Typography>
                            </Box>
                        </Box>
                    </a>

                    <Typography variant='h4' fontWeight={'bold'} marginY={3} textAlign={'center'}  sx={{ WebkitTextStroke: "1px black", color: "#ffa400", display: { md: "block", xs: "none" }, fontFamily: "'Modak'", textShadow: "0.1em 0.1em 0.1em #333" }}>
                        {t("home.discordCallToAction.subTitle")}
                    </Typography>
                </Box>
            </Box>

        </Box >
    )
}
