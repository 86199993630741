import { Box, Button, Grid, ThemeProvider, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { appTheme } from "../../styles/MUI/Theme"
import LanguageIcon from '@mui/icons-material/Language';
import logo from "../img/TesseractLogoFooter.png"


export const Footer = () => {

    const { t, i18n } = useTranslation()

    const routes = [
        {
            path: `/${i18n.language}`,
            title: t("Navbar.home")
        },
        {
            path: `/${i18n.language}/gallery`,
            title: t("Navbar.gallery")
        },
        /*         {
                    path: `/${i18n.language}/news`,
                    title: t("Navbar.news")
                } */
    ]
    return (
        <ThemeProvider theme={appTheme}>
            <Box py={4} px={2} sx={{ backgroundColor: "#231f20" }}>
                <Grid container spacing={{ xs: 2 }}>
                    <Grid item xs={12} px={2}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center", width: "100%" }}>
                            <Box component={"a"} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                <img style={{ width: "200px" }} src={logo} alt="hola vincent" />

                            </Box>
                            <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                {
                                    routes.map((route, index) => {
                                        return (
                                            <Box mt={1} mr={index === routes.length - 1 ? 0 : 2}>
                                                <Link to={route.path} style={{ textDecoration: "none", color: "#fff" }}>{route.title}</Link>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item xs={12} px={2}>
                        <Link to={`/${i18n.language}/language`} style={{ textDecoration: "none", color: "#fff" }}>
                            <Typography variant="h2" sx={{ fontSize: "0.875rem", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}><LanguageIcon fontSize="small" sx={{ mx: 0.5 }} />{t("language.language")}</Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} px={2}>
                        <Typography variant="h2" component="h3" sx={{ fontSize: "0.875rem", textAlign: "center" }} gutterBottom>® Tesseract 2023. All rights reserved</Typography>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    )
}
