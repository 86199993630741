import { createSlice } from '@reduxjs/toolkit'
import { news } from '../../models/News';

export interface newsStore {
  news: news[],
  error: string,
  status: string,
}

const initialState: newsStore = {
  status: "newsNotReceived",
  error: "",
  news: [],
}

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    newsReceived: (state, {payload}) => {
      state.status = "newsReceived";
      state.news = payload;
    },
    newsNotReceived: (state, {payload}) => {
      state.status = "newsNotReceived";
      state.error = payload;
    },
    checkingNews: (state) => {
      state.status = "checkingNews";
    }
  }
})

export const { newsReceived, newsNotReceived, checkingNews } = newsSlice.actions