import { FunctionComponent, MouseEvent } from 'react'

import { useSpring, animated, to } from 'react-spring'
import { Skin } from '../../../../models/Skins'
import './app.scss'

const calc = (x: number, y: number) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x: number, y: number) => `translate3d(${x / 30}px,${y / 30}px,0)`
// const trans2 = (x: number, y: number) => `translate3d(${x / 35}px,${y / 8 - 230}px,0)`
// const trans3 = (x: number, y: number) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`
// const trans4 = (x: number, y: number) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`

interface props {
  skin: Skin,
}
const SkinMove = ({ skin }: props) => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  return (
    <div
      // style={{ display: `${current === `${index}` ? "flex" : "none"}` }}
      className="container"
      onMouseMove={({ clientX: x, clientY: y }: MouseEvent) => set({ xy: calc(x, y) })}
    >
      {/* <div className='circle' style={{ borderRadius: "50%", position: "absolute", border: "#ffa400 solid" }}></div> */}

      <animated.div className="card1" style={{ transform: to(props.xy, trans1), backgroundImage: `url(${skin.imgUrl})` }} />

    </div>
  )
}

export default SkinMove
