import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

export const RegisterOIDC = () => {
    const { i18n } = useTranslation()

    return (
        <Navigate to={`/${i18n.language.toLowerCase()}`} />
    )
}
