// import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#231f20',
            contrastText: "#f2f2f2",
            light: "#f2f2f2",
            dark: "#231f20"
        },
        secondary: {
            main: '#00C04A',
            contrastText: "#fff",
            light: "#00C04A",
            dark: "#00C04A"
        },
        info: {
            main: '#FFA400',
            contrastText: "#fff",
            light: "#FFA400",
            dark: "#FFA400"
        }
    },
});