import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { parseJwt } from "../functions/DecodeJWT";
import { useAppDispatch } from "../hooks/hooks";
import { checkingAuth, setUser } from "../store/auth/authThunk";
import axios from "axios";
import { Box } from "@mui/material";
import { LoadingCube } from "../components/CustomComponents/loader/LoadingCube";
export const LoginPage = () => {
    const dispatch = useAppDispatch()
    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('jwtToken') as string;


    const user = parseJwt(token)

    useEffect(() => {
        dispatch(checkingAuth())
        if (user) {
            window.localStorage.setItem("jwt", token);
            axios.get(`${process.env.REACT_APP_TAUTH_URL}/api/account/getuserinformation?Id=${user.uid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {

                    dispatch(setUser({ ...user, profileImage: response.data.profilePicture }))
                    navigate("/")
                })
                .catch((error): any => {
                    navigate("/login")
                });

        } else {
            // navigate("/error")
        }
    }, [])
    return (
        <Box
            sx={{
                minHeight:"100vh",
                display:'flex',
                alignItems:"center",
                justifyContent:"center",
                backgroundColor:"#231f20"
            }}
        >
            <LoadingCube />
        </Box>
    )
}
